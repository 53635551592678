// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action, Store } from '../ngrx-actions';
import {
    LoadAllActiveSectionsAction, LoadApplianceJobsAction, LoadApplianceServicesAction, UpdateInStateApplianceServiceAction
} from './transfer-management.actions';
import { TransferManagementState } from './transfer-management.state';

@Store({} as TransferManagementState)
export class TransferManagementStore {

    @Action(LoadAllActiveSectionsAction)
    public loadAppliances(state: TransferManagementState, { activeSections }: LoadAllActiveSectionsAction) {
        return { ...state, activeSections } as TransferManagementState;
    }

    @Action(LoadApplianceJobsAction)
    public loadJobs(state: TransferManagementState, { jobs }: LoadApplianceJobsAction) {
        return { ...state, jobs } as TransferManagementState;
    }

    @Action(LoadApplianceServicesAction)
    public loadServices(state: TransferManagementState, { services }: LoadApplianceServicesAction) {
        return { ...state, services } as TransferManagementState;
    }

    @Action(UpdateInStateApplianceServiceAction)
    public updateService(state: TransferManagementState, { service }: UpdateInStateApplianceServiceAction) {
        if (state.services != null) {
            const index = state.services.findIndex(x => x.id === service.id);
            if (index >= 0) {
                const newServices = [...state.services];
                newServices[index] = { ...service };
                return { ...state, services: newServices } as TransferManagementState;
            }
        }
    }
}
