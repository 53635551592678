// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { NgxAlertService, getMessageFromError } from 'ngx-shared';

import { MerlinManagementService } from '@/_services/merlin-management.service';
import {
    FetchAllMerlinAppliancesAction, FetchAllMerlinAppliancesFailedAction, FetchAllMerlinAppliancesOkAction, LoadAllMerlinAppliancesAction,
    FetchApplianceJobsAction, FetchApplianceJobsFailedAction, FetchApplianceJobsOkAction, LoadApplianceJobsAction,
    FetchApplianceServicesAction, FetchApplianceServicesFailedAction, FetchApplianceServicesOkAction, LoadApplianceServicesAction,
    ClearMerlinManagementDataAction,
    RunServiceCommandAction, RunServiceCommandFailedAction, RunServiceCommandOkAction, UpdateInStateApplianceServiceAction,
    FetchMerlinReleaseAction,
    LoadMerlinReleaseInfoAction,
    FetchMerlinReleaseFailedAction,
    FetchMerlinReleaseOkAction,
    ApproveMerlinReleaseAction,
    ApproveMerlinReleaseFailedAction,
    ApproveMerlinReleaseOkAction,
    FetchApplianceRoutingTableAction,
    LoadApplianceRoutingTableAction,
    FetchApplianceRoutingTableOkAction,
    FetchApplianceRoutingTableFailedAction,
    FetchApplianceAuthSettingsAction,
    LoadApplianceAuthSettingsAction,
    FetchApplianceAuthSettingsOkAction,
    FetchApplianceAuthSettingsFailedAction,
    FetchApplianceRepoStatisticsAction,
    LoadApplianceRepoStatisticsAction,
    FetchApplianceRepoStatisticsOkAction,
    FetchApplianceRepoStatisticsFailedAction,
    UpdateApplianceAuthSettingsAction,
    UpdateApplianceAuthSettingsOkAction,
    UpdateApplianceAuthSettingsFailedAction
} from './merlin-management.actions';

@Injectable()
export class MerlinManagementEffects {

    public onFetchAllMerlinAppliances$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchAllMerlinAppliancesAction),
        switchMap(() => this.merlinManagementService.getAll().pipe(
            switchMap(x => [new LoadAllMerlinAppliancesAction(x), new FetchAllMerlinAppliancesOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchAllMerlinAppliances ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchAllMerlinAppliancesFailedAction(error));
            })
        ))
    ));

    public onFetchApplianceJobs$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchApplianceJobsAction),
        switchMap(x => this.merlinManagementService.getJobs(x.applianceId, x.instanceId).pipe(
            switchMap(jobs => [new LoadApplianceJobsAction(jobs), new FetchApplianceJobsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchApplianceJobs ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchApplianceJobsFailedAction(error));
            })
        ))
    ));
    
    public onFetchApplianceServices$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchApplianceServicesAction),
        switchMap(x => this.merlinManagementService.getServices(x.applianceId).pipe(
            switchMap(services => [new LoadApplianceServicesAction(services), new FetchApplianceServicesOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchApplianceServices ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchApplianceServicesFailedAction(error));
            })
        ))
    ));
    
    public onFetchApplianceRoutingTable$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchApplianceRoutingTableAction),
        switchMap(x => this.merlinManagementService.getRoutingTable(x.applianceId).pipe(
            switchMap(routingTable => [new LoadApplianceRoutingTableAction(routingTable), new FetchApplianceRoutingTableOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchApplianceRoutingTable ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchApplianceRoutingTableFailedAction(error));
            })
        ))
    ));
    
    public onFetchApplianceAuthSettings$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchApplianceAuthSettingsAction),
        switchMap(x => this.merlinManagementService.getAuthSettings(x.applianceId, x.poolName).pipe(
            switchMap(authSettings => [new LoadApplianceAuthSettingsAction(authSettings), new FetchApplianceAuthSettingsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchApplianceAuthSettings ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchApplianceAuthSettingsFailedAction(error));
            })
        ))
    ));

    public onUpdateApplianceAuthSettings$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateApplianceAuthSettingsAction),
        switchMap(x => this.merlinManagementService.updateAuthSettings(x.applianceId, x.poolName, x.updateAuthSettingsModel).pipe(
            switchMap(() => [new UpdateApplianceAuthSettingsOkAction(), new FetchApplianceAuthSettingsAction(x.applianceId, x.poolName)]),
            catchError(error => {
                this.logger.error('Error in UpdateApplianceAuthSettings', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateApplianceAuthSettingsFailedAction(error));
            })
        ))
    ));
    
    public onFetchApplianceRepoStatistics$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchApplianceRepoStatisticsAction),
        switchMap(x => this.merlinManagementService.getRepoStatistics(x.applianceId, x.instanceId).pipe(
            switchMap(repoStatistics => [new LoadApplianceRepoStatisticsAction(repoStatistics), new FetchApplianceRepoStatisticsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchApplianceRepoStatistics ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchApplianceRepoStatisticsFailedAction(error));
            })
        ))
    ));

    public onClearMerlinManagementData$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearMerlinManagementDataAction),
        switchMap(() => [
            new LoadApplianceJobsAction(),
            new LoadApplianceServicesAction(),
            new LoadApplianceRepoStatisticsAction(),
            new LoadApplianceAuthSettingsAction(),
            new LoadApplianceRepoStatisticsAction()
        ])
    ));

    public onRunServiceCommand$ = createEffect(() => this.actions$.pipe(
        ofAction(RunServiceCommandAction),
        switchMap(a => this.merlinManagementService.runServiceCommand(a.applianceId, a.serviceId, a.commandName).pipe(
            switchMap(x => [new UpdateInStateApplianceServiceAction(x), new RunServiceCommandOkAction()]),
            catchError(error => {
                this.logger.error('Error in RunServiceCommand', error);
                this.alertService.error(getMessageFromError(error));
                return of(new RunServiceCommandFailedAction(error));
            })
        ))
    ));

    public onFetchMerlinRelease$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchMerlinReleaseAction),
        switchMap(() => this.merlinManagementService.getMerlinReleaseInfo().pipe(
            switchMap(releaseInfo => [new LoadMerlinReleaseInfoAction(releaseInfo), new FetchMerlinReleaseOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchMerlinRelease ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchMerlinReleaseFailedAction(error));
            })
        ))
    ));
    
    public onApproveMerlinRelease$ = createEffect(() => this.actions$.pipe(
        ofAction(ApproveMerlinReleaseAction),
        switchMap(a => this.merlinManagementService.approveMerlinRelease(a.releaseId).pipe(
            switchMap(x => [new LoadMerlinReleaseInfoAction(x), new ApproveMerlinReleaseOkAction()]),
            catchError(error => {
                this.logger.error('Error in ApproveMerlinRelease ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ApproveMerlinReleaseFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly merlinManagementService: MerlinManagementService
    ) { }
}
