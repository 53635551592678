<app-modal-dialog #dialog="bs-modal"
                  headerText="Issue details"
                  class="manage-monitors-modal"
                  modalClass="modal-dialog modal-xl"
                  [actions]="dialogActions"
                  (close)="onModelClose()"
>
  <div [class.invisible]="issues?.length" class="d-flex align-items-center justify-content-center h-75 fixed-top position-absolute">
    No issues available
  </div>

  <div [class.invisible]="!issues?.length" class="col-12 form-horizontal p-0">
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Subject type</label>
      <label class="col pl-0 form-control-plaintext"
             id="subject-type"><b>{{currentIssue?.ruleInstance?.subjectType}}</b></label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Subject name</label>
      <label class="col pl-0 form-control-plaintext" id="subject-name">
        <b class="white-space-preserve">{{currentIssue?.ruleInstance?.subjectName}}</b>
      </label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Rule name</label>
      <label class="col pl-0 form-control-plaintext"
             id="rule"><b>{{currentIssue?.ruleInstance?.monitor}}</b></label>
    </div>
    <tabset #tabSet class="tab-size-fixed">
      <tab heading="Information">
        <div class="form-row">
          <label class="col-4 col-form-label text-muted">Status</label>
          <label class="col pl-0 form-control-plaintext font-weight-bold" id="status">{{getIndicatorStatusText(currentIssue?.status)}}</label>
        </div>
        <div class="form-row">
          <label class="col-4 col-form-label text-muted">Reason code</label>
          <label class="col pl-0 form-control-plaintext font-weight-bold"
                 id="reason-code">{{currentIssue?.reasonCodeDescription}}</label>
        </div>
        <div class="form-row">
        <label class="col-4 col-form-label text-muted">Problem time</label>
        <label class="col pl-0 form-control-plaintext" id="problem-time"><b>{{currentIssue?.problemTime ? 'Yes' : 'No'}}</b></label>
        </div>
        <div class="form-row">
        <label class="col-4 col-form-label text-muted">Duration</label>
        <label class="col pl-0 form-control-plaintext" id="duration">{{currentIssue?.duration}}</label>
        </div>
        <div class="form-row">
        <label class="col-4 col-form-label text-muted">Start time</label>
        <label class="col pl-0 form-control-plaintext"
                id="start-time">{{currentIssue?.startTime | date: 'medium'}}</label>
        </div>
        <div class="form-row">
        <label class="col-4 col-form-label text-muted">End time</label>
        <label class="col pl-0 form-control-plaintext"
                id="end-time">{{currentIssue?.endTime | date: 'medium'}}</label>
        </div>
        <div class="form-row">
        <label class="col-4 col-form-label text-muted">Start time index</label>
        <label class="col pl-0 form-control-plaintext"
                id="start-time-index">{{currentIssue?.startTimeIndex | date: 'medium'}}</label>
        </div>
        <div class="form-row">
        <label class="col-4 col-form-label text-muted">End time index</label>
        <label class="col pl-0 form-control-plaintext"
                id="end-time-index">{{currentIssue?.endTimeIndex | date: 'medium'}}</label>
        </div>
        <div class="form-row">
        <label class="col-4 col-form-label text-muted">Start depth index</label>
        <label class="col pl-0 form-control-plaintext"
                id="start-depth-index">{{currentIssue?.startDepthIndex}} {{currentIssue?.startDepthIndexUom}}</label>
        </div>
        <div class="form-row">
        <label class="col-4 col-form-label text-muted">End depth index</label>
        <label class="col pl-0 form-control-plaintext"
                id="end-depth-index">{{currentIssue?.endDepthIndex}} {{currentIssue?.endDepthIndexUom}}</label>
        </div>
        <div *ngIf="isAlertLinkShown" class="form-row align-items-center" id="alert-details">
        <label class="col-4 col-form-label text-muted">Associated with</label>
        <a id="alert-link"
            title="Open alert details in a new tab"
            target="_blank"
            [routerLink]="['/alerts']"
            [queryParams]="alertQueryParams"
            queryParamsHandling="merge"
        >Alert details</a>
        </div>

        <div class="col d-flex justify-content-center mt-3">
        <pagination
            [totalItems]="issues?.length"
            [itemsPerPage]="1"
            [boundaryLinks]="true"
            [maxSize]="5"
            (pageChanged)="onPageChanged($event)"
            [(ngModel)]="currentPage"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="1"
            [lastText]="issues?.length || 1">
        </pagination>
        </div>
      </tab>
      <tab heading="Conditions">
        <div class="form-row d-flex align-items-center">
            <label class="col-4 col-form-label text-muted">Opening Conditions</label>
            <label class="col pl-0 col pl-0 form-control-plaintext">{{currentIssue?.comment}}</label>
        </div>
        <div class="form-row d-flex align-items-center">
            <label class="col-4 col-form-label text-muted">Reason for Closing</label>
            <label class="col pl-0 col pl-0 form-control-plaintext">{{currentIssue?.closeReason}}</label>
        </div>
        <div class="col d-flex justify-content-center mt-3">
            <pagination
                [totalItems]="issues?.length"
                [itemsPerPage]="1"
                [boundaryLinks]="true"
                [maxSize]="5"
                (pageChanged)="onPageChanged($event)"
                [(ngModel)]="currentPage"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="1"
                [lastText]="issues?.length || 1">
            </pagination>
        </div>
      </tab>
      <tab heading="Details">
        <div *ngFor="let curveInfo of currentIssue?.taggedCurvesInfo?.split(',')" class="form-row d-flex align-items-center">
            <label class="col-4 col-form-label text-muted">{{curveInfo?.split(':')[0]}}</label>
            <label class="col pl-0 col pl-0 form-control-plaintext">{{curveInfo?.split(':')[1]}}</label>
        </div>
        <div class="col d-flex justify-content-center mt-3">
            <pagination
                [totalItems]="issues?.length"
                [itemsPerPage]="1"
                [boundaryLinks]="true"
                [maxSize]="5"
                (pageChanged)="onPageChanged($event)"
                [(ngModel)]="currentPage"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="1"
                [lastText]="issues?.length || 1">
            </pagination>
        </div>
      </tab>
      <tab heading="Suppression">
        <app-issue-details-suppression-tab #suppressionTab [issues]="issues" [sectionId]="data?.sectionId"></app-issue-details-suppression-tab>
      </tab>
      <tab *ngIf="isLastIssueOpen" heading="Issues in Other Sections">
        <div class="form-row d-flex align-items-center pb-2">
            <label class="col-form-label text-muted" for="my-rigs-check">My Rigs</label>
            <label class="switch switch-label switch-pill switch-sm switch-primary mb-0" title="Show only orders from My Rigs">
              <input id="my-rigs-check" class="switch-input" type="checkbox" [checked]="filterMyRigs" (change)="onFilterMyRigsChange()">
              <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
            </label>
            <button class="btn btn-primary ml-2" title="Find matching Issues in other active Sections" (click)="onMatchingIssuesClick()">
                Find Matching Issues
            </button>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-12 p-xl-1 p-lg-1 p-sm-1" *ngFor="let res of matchingIssuesResult">
                <div class="card mb-0">
                    <div class="card-body p-2 d-flex align-items-start">
                        <a [routerLink]="['/monitor', 'section', res.sectionId]" [queryParams]="getMonitorQueryParams(res.categoryService)" target="_blank">
                            <i class="fa fa-clipboard-list-check p-1 font-2xl mt-1 col-static align-self-center" [style.color]="getIconColor(res)"></i>
                        </a>
                        <div class="col pr-0 overflow-hidden">
                            <div class="text-muted text-uppercase font-weight-bold small">
                                <label class="mb-0">
                                    <b>{{res.orderName}}</b>
                                </label>
                            </div>
                            <div class="text-muted text-uppercase font-weight-bold small">
                                <label class="mb-0">
                                    <b>{{res.sectionName}}</b> : <b>{{res.targetName}}</b>
                                </label>
                            </div>
                            <div class="text-muted text-uppercase font-weight-bold small">
                                <label class="mb-0">
                                    <b>{{getIndicatorStatusText(res.issueStatus)}}</b>
                                </label>
                            </div>
                            <div class="text-muted text-uppercase font-weight-bold small">
                                <label class="mb-0">
                                    <b>{{getIssueDuration(res.issueTimeProblemDuration)}}</b> <b>{{getIssueReason(res.issueReasonCode, res.issueReasonCodeDescription)}}</b>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </tab>
    </tabset>
  </div>
</app-modal-dialog>
