// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { IndicatorStatus } from './indicator-status';
import { ProblemTimeData } from './problem-time-data';

export class Issue extends ProblemTimeData {
    public id: number;
    public ruleInstanceId: number;
    public alertId: number;
    public status: IndicatorStatus;

    public startTime: Date;
    public startTimeTicks: string;
    /** End time of the issue. Opened issues do not have end time */
    public endTime?: Date;
    public startTimeIndex?: Date;
    public endTimeIndex?: Date;
    public startDepthIndex?: number;
    public startDepthIndexUom?: string;
    public endDepthIndex?: number;
    public endDepthIndexUom?: string;

    public reasonCode: number;
    public reasonCodeDescription: string;
    /** Created by the Rule */
    public comment?: string;
    public closeReason?: string;
    public taggedCurvesInfo?: string;
    /** Is the issue is marked as problem time */
    public problemTime: boolean;
}
