//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

import { SectionMonitorRuleInstance, IndicatorStatus } from '@/_models';

export class IssueDetailsDialogVm {
    /** ids are used for navigation */
    public sectionId?: number;
    public targetId?: number;
    public issues: IssueDetailsDialogIssueVm[];
    public rigTimeZone?: string;
    public ruleInstanceIds?: number[];
}

export class IssueDetailsDialogIssueVm {
    public id: number;
    public ruleInstance: SectionMonitorRuleInstance;
    public status: IndicatorStatus;
    public alertId: number;
    /** TBD */
    public reasonCode: number
    public reasonCodeDescription: string;
    /** Created by the Rule */
    public comment?: string;
    public closeReason?: string;
    public taggedCurvesInfo?: string;

    public startTime: Date;
    public startTimeTicks: string;
    /** End time of the issue. Opened issues do not have end time */
    public endTime?: Date;
    public startTimeIndex?: Date;
    public endTimeIndex?: Date;
    public startDepthIndex?: number;
    public startDepthIndexUom?: string;
    public endDepthIndex?: number;
    public endDepthIndexUom?: string;

    public duration: string;
    /** Is the issue marked as problem time */
    public problemTime: boolean;
}
